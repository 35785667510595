<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <!-- v-if="roleName == '超级系统管理员' || roleName == '平台管理员'" -->
        <div style="display: flex">
          <el-col>
            <el-button plain class="add-column-btn bbox" @click="gotoAddDetail('')" type="primary" v-if="roleName == '超级系统管理员' || roleName == '平台管理员'">
              <i class="el-icon-plus fw900 f16"></i> 新增
            </el-button>
          </el-col>
          <el-col>
            <el-button plain class="add-column-btn bbox" @click="getDownLoad" type="primary">
              <i class="el-icon-plus fw900 f16"></i> 下载导入模版
            </el-button>
          </el-col>
          <el-col>
            <el-upload :show-file-list="false" style="display: inline-block; margin-left: 10px" class="upload-demo"
              ref="uploadF" :action="$uploadURL" :on-success="uploadSuccess" accept=".XLSX">
              <el-button plain class="add-column-btn bbox" type="primary">
                <i class="el-icon-plus fw900 f16"></i> 导入
              </el-button>
            </el-upload>
          </el-col>
          <el-col>
            <el-button plain class="add-column-btn bbox" type="primary" @click="getExport">
              <i class="el-icon-plus fw900 f16"></i> 导出
            </el-button>
          </el-col>
        </div>

        <el-col>
          <div class="flex align-center justify-end header-search-box">
            <el-input placeholder="请输入街道名称" clearable v-model="searchInput" class="header-search-input ml10"
              v-if="roleName != '街道管理员'">
              <el-button slot="append" icon="el-icon-search" class="textblue searchBtn" @click="search()"></el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="tableData" border fit :height="height" highlight-current-row style="width: 100%"
        @sort-change="sortChange">
        <el-table-column label="省" align="center" prop="省名称">
          <template slot-scope="scope">
            <span>{{ scope.row.provinceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="城市" align="center" prop="城市名称">
          <template slot-scope="scope">
            <span>{{ scope.row.cityName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="区县" align="center" prop="区县名称">
          <template slot-scope="scope">
            <span>{{ scope.row.districtName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="街道" align="center" prop="街道名称">
          <template slot-scope="scope">
            <span>{{ scope.row.streetName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="后台登录账号" align="center" prop="后台登录账号">
          <template slot-scope="scope">
            <span>{{ scope.row.loginName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户名称" align="center" prop="用户名称">
          <template slot-scope="scope">
            <span>{{ scope.row.memberName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" align="center" prop="手机号">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="画图" placement="bottom">
              <i class="el-icon-edit textblue f16" @click="gotoDetail(scope.row.id, scope.row.userCode)"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="重置密码" placement="bottom" v-if="roleName == '平台管理员'">
              <i class="el-icon-edit textblue f16 ml20" @click="reSetPassword(scope.row.id)"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="bottom"
              v-if="roleName == '街道管理员' && login_name !== ''">
              <i class="el-icon-delete textblue f16 ml20" @click="
                del(scope.row.streetCode, scope.row.id, scope.row.phone)
              "></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      downloadUrl: '',
      download: '',
      height: window.innerHeight - 256, //表格高度
      value: "",
      searchInput: "",
      tableData: [],
      loading: true,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          streetCode: localStorage.getItem("streetCode")
        }
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: ""
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      roleName: "",
      login_name: ""
    };
  },
  created () {
    this.queryInfo.currPage = this.$route.query.currPage;
    this.queryInfo.pageSize = this.$route.query.pageSize;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted () {
    this.loading = false;
    this.roleName = localStorage.getItem("roleName");
    this.login_name = localStorage.getItem("login_name");
  },
  destroyed () {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //条件查询
    search () {
      this.queryInfo.condition.streetName = this.searchInput;
      this.getListData();
    },
    //localStorage.getItem("phone")
    //localStorage.getItem("userCode")
    //导出按钮点击事件
    getExport () {
      var that = this;
      that.$http
        .post("/street/exportExcel", {memberCode:localStorage.getItem("userCode"),phone:localStorage.getItem("phone")})
        .then(function (res) {
          if (res.data.code == 200) {
          window.location.href = res.data.data
          that.$notify.success({
            title: "提示",
            message: "导出成功"
          });
        } else {
          that.$notify.error({
            title: "提示",
            message: res.data.message
          });
          return
        }
        });
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange () { },
    //跳转到详情
    gotoDetail (id) {
      var that = this;
      this.$router.push({
        path: "/street/Map",
        query: {
          id: id,
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize
        }
      });
    },

    //跳转到详情
    gotoAddDetail (id) {
      var that = this;
      this.$router.push({
        path: "/street/details",
        query: {
          id: id,
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize
        }
      });
    },
    //重置密码
    async reSetPassword (id) {
      const confirmResult = await this.$confirm("确定重置密码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/street/reSetPassword", { id: id })
          .then(function (response) {
            console.log(response);
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "重置密码成功",
                showClose: true
              });
              that.getListData();
            } else {
              that.$notify.error({
                title: "提示",
                message: response.data.message,
                showClose: true
              });
            }
          });
      }
    },
    // 删除用户
    async del (code, id, phone) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/street/deleteStreetInfoRelated", {
            streetCode: code,
            id: id,
            phone: phone
          })
          .then(function (response) {
            console.log(response);
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true
              });
              that.getListData();
            } else {
              that.$notify.error({
                title: "提示",
                message: response.data.message,
                showClose: true
              });
            }
          });
      }
    },
    getListData () {
      // 获取列表
      var that = this;
      that.$http.post("/street/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage;
          that.tableData = response.data.data.data;
        }
      });
    },
    // 监听表格高度
    getHeight () {
      this.height = window.innerHeight - 256;
    },

    //导入模板
    getDownLoad () {
      window.location = "/file/Import.xlsx?version=" + Math.random;
    },

    //上传
    uploadSuccess (response, file, filelist) {
      var that = this;
      let temp = null;
      temp = response.data.url;
      console.log(response);
      that.$http
        .post("/street/uploadExcel", {
          fielUrl: temp
        })
        .then(function (response) {
          if (response.data.code === 200) {
            that.$notify.success({
              title: "提示",
              message: "导入成功",
              showClose: true
            });
            that.dialogVisible = false;
            that.getListData();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true
            });
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}

.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;

    .el-select > .el-input {
      width: 200px;
    }

    .header-search-box .header-search-input {
      width: 325px;
    }

    input::placeholder {
      font-size: 12px;
    }

    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }

    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
